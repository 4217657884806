import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

// ⚠️ When adding a new environment variable,
// you also need to update the `turbo.json` file
// to make it available in the build process.

export const env = createEnv({
  server: {
    APIFY_ACTOR_ID: z.string().min(1),
    APIFY_API_KEY: z.string().min(1),
    APIFY_USERNAME: z.string().min(1),
    AZURE_AD_CLIENT_ID: z.string().min(1),
    AZURE_AD_CLIENT_SECRET: z.string().min(1),
    AZURE_AD_OAUTH_CONNECTION_NAME: z.string().min(1),
    AZURE_AD_TENANT_ID: z.string().min(1),
    AZURE_AD_TENANT_USER_ID: z.string().min(1),
    CLOUDINARY_API_SECRET: z.string().min(1),
    DATABASE_URL: z.string().min(1),
    DATABASE_URL_INBOXES: z.string().min(1),
    EMAIL_FROM: z.string().email(),
    FACEBOOK_LOGIN_APP_ID: z.string().min(1),
    FACEBOOK_LOGIN_APP_SECRET: z.string().min(1),
    GCP_API_KEY: z.string().min(1),
    GOOGLE_CLIENT_ID: z.string().min(1),
    GOOGLE_CLIENT_SECRET: z.string().min(1),
    KV_REST_API_READ_ONLY_TOKEN: z.string().min(1),
    KV_REST_API_TOKEN: z.string().min(1),
    KV_REST_API_URL: z.string().min(1),
    KV_URL: z.string().min(1),
    LANGFUSE_BASE_URL: z.string().min(1),
    LANGFUSE_PUBLIC_KEY: z.string().min(1),
    LANGFUSE_SECRET_KEY: z.string().min(1),
    LINKEDIN_CLIENT_ID: z.string().min(1),
    LINKEDIN_CLIENT_SECRET: z.string().min(1),
    META_APP_ID: z.string().min(1),
    META_APP_SECRET: z.string().min(1),
    META_PAGE_ACCESS_TOKEN: z.string().min(1),
    META_PAGE_ID: z.string().min(1),
    META_VERIFY_TOKEN: z.string().min(1),
    NEXTAUTH_SECRET: z.string().min(1),
    NEXTAUTH_URL: z.string().url().optional(),
    NODE_ENV: z.enum(["development", "production"]).default("development"),
    OPENAI_API_KEY: z.string().min(1),
    PINECONE_API_KEY: z.string().min(1),
    PINECONE_CLOUD: z.string().min(1),
    PINECONE_ENVIRONMENT: z.string().min(1),
    PINECONE_REGION: z.string().min(1),
    QSTASH_TOKEN: z.string().min(1),
    QSTASH_URL: z.string().min(1),
    QSTASH_CURRENT_SIGNING_KEY: z.string().min(1),
    QSTASH_NEXT_SIGNING_KEY: z.string().min(1),
    ROOT_DOMAIN: z.string().min(1),
    SENDGRID_API_KEY: z.string().min(1),
    SLACK_ADMIN_MEMBER_ID: z.string().min(1),
    SLACK_BOT_TOKEN: z.string().min(1),
    SLACK_SIGNING_SECRET: z.string().min(1),
    STRIPE_API_KEY: z.string().min(1),
    STRIPE_PRO_MONTHLY_PLAN_ID: z.string().min(1),
    STRIPE_SOLO_MONTHLY_PLAN_ID: z.string().min(1),
    STRIPE_BUSINESS_MONTHLY_PLAN_ID: z.string().min(1),
    STRIPE_PRO_YEARLY_PLAN_ID: z.string().min(1),
    STRIPE_SOLO_YEARLY_PLAN_ID: z.string().min(1),
    STRIPE_BUSINESS_YEARLY_PLAN_ID: z.string().min(1),
    STRIPE_WEBHOOK_SECRET: z.string().min(1),
    TIPTAP_AI_SECRET: z.string().min(1),
    TIPTAP_COLLAB_SECRET: z.string().min(1),
    UNIFIED_API_KEY: z.string().min(1),
    UNIFIED_ENVIRONMENT: z.string().min(1),
    UNIFIED_WORKSPACE_ID: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_APP_URL: z.string().url(),
    NEXT_PUBLIC_COLLAB_DOC_PREFIX: z.string().min(1),
    NEXT_PUBLIC_CLOUDINARY_API_KEY: z.string().min(1),
    NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().url(),
    NEXT_PUBLIC_PUSHER_APP_ID: z.string().min(1),
    NEXT_PUBLIC_PUSHER_CLUSTER: z.string().min(1),
    NEXT_PUBLIC_PUSHER_KEY: z.string().min(1),
    NEXT_PUBLIC_PUSHER_SECRET: z.string().min(1),
    NEXT_PUBLIC_REPLICACHE_LICENSE_KEY: z.string().min(1),
    NEXT_PUBLIC_ROOT_DOMAIN: z.string().min(1),
    NEXT_PUBLIC_TIPTAP_AI_APP_ID: z.string().min(1),
    NEXT_PUBLIC_TIPTAP_AI_BASE_URL: z.string().url(),
    NEXT_PUBLIC_TIPTAP_COLLAB_APP_ID: z.string().min(1),
    NEXT_PUBLIC_URL: z.string().url(),
  },
  runtimeEnv: {
    APIFY_ACTOR_ID: process.env.APIFY_ACTOR_ID,
    APIFY_API_KEY: process.env.APIFY_API_KEY,
    APIFY_USERNAME: process.env.APIFY_USERNAME,
    AZURE_AD_CLIENT_ID: process.env.AZURE_AD_CLIENT_ID,
    AZURE_AD_CLIENT_SECRET: process.env.AZURE_AD_CLIENT_SECRET,
    AZURE_AD_OAUTH_CONNECTION_NAME: process.env.AZURE_AD_OAUTH_CONNECTION_NAME,
    AZURE_AD_TENANT_ID: process.env.AZURE_AD_TENANT_ID,
    AZURE_AD_TENANT_USER_ID: process.env.AZURE_AD_TENANT_USER_ID,
    CLOUDINARY_API_SECRET: process.env.CLOUDINARY_API_SECRET,
    DATABASE_URL: process.env.DATABASE_URL,
    DATABASE_URL_INBOXES: process.env.DATABASE_URL_INBOXES,
    EMAIL_FROM: process.env.EMAIL_FROM,
    FACEBOOK_LOGIN_APP_ID: process.env.FACEBOOK_LOGIN_APP_ID,
    FACEBOOK_LOGIN_APP_SECRET: process.env.FACEBOOK_LOGIN_APP_SECRET,
    GCP_API_KEY: process.env.GCP_API_KEY,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    KV_REST_API_READ_ONLY_TOKEN: process.env.KV_REST_API_READ_ONLY_TOKEN,
    KV_REST_API_TOKEN: process.env.KV_REST_API_TOKEN,
    KV_REST_API_URL: process.env.KV_REST_API_URL,
    KV_URL: process.env.KV_REST_API_URL,
    LANGFUSE_BASE_URL: process.env.LANGFUSE_BASE_URL,
    LANGFUSE_PUBLIC_KEY: process.env.LANGFUSE_PUBLIC_KEY,
    LANGFUSE_SECRET_KEY: process.env.LANGFUSE_SECRET_KEY,
    LINKEDIN_CLIENT_ID: process.env.LINKEDIN_CLIENT_ID,
    LINKEDIN_CLIENT_SECRET: process.env.LINKEDIN_CLIENT_SECRET,
    META_APP_ID: process.env.META_APP_ID,
    META_APP_SECRET: process.env.META_APP_SECRET,
    META_PAGE_ACCESS_TOKEN: process.env.META_PAGE_ACCESS_TOKEN,
    META_PAGE_ID: process.env.META_PAGE_ID,
    META_VERIFY_TOKEN: process.env.META_VERIFY_TOKEN,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_COLLAB_DOC_PREFIX: process.env.NEXT_PUBLIC_COLLAB_DOC_PREFIX,
    NEXT_PUBLIC_CLOUDINARY_API_KEY: process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY,
    NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME:
      process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_PUSHER_APP_ID: process.env.NEXT_PUBLIC_PUSHER_APP_ID,
    NEXT_PUBLIC_PUSHER_CLUSTER: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
    NEXT_PUBLIC_PUSHER_KEY: process.env.NEXT_PUBLIC_PUSHER_KEY,
    NEXT_PUBLIC_PUSHER_SECRET: process.env.NEXT_PUBLIC_PUSHER_SECRET,
    NEXT_PUBLIC_REPLICACHE_LICENSE_KEY:
      process.env.NEXT_PUBLIC_REPLICACHE_LICENSE_KEY,
    NEXT_PUBLIC_ROOT_DOMAIN: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
    NEXT_PUBLIC_TIPTAP_AI_APP_ID: process.env.NEXT_PUBLIC_TIPTAP_AI_APP_ID,
    NEXT_PUBLIC_TIPTAP_AI_BASE_URL: process.env.NEXT_PUBLIC_TIPTAP_AI_BASE_URL,
    NEXT_PUBLIC_TIPTAP_COLLAB_APP_ID:
      process.env.NEXT_PUBLIC_TIPTAP_COLLAB_APP_ID,
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
    NODE_ENV: process.env.NODE_ENV,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    PINECONE_API_KEY: process.env.PINECONE_API_KEY,
    PINECONE_CLOUD: process.env.PINECONE_CLOUD,
    PINECONE_ENVIRONMENT: process.env.PINECONE_ENVIRONMENT,
    PINECONE_REGION: process.env.PINECONE_REGION,
    QSTASH_URL: process.env.QSTASH_URL,
    QSTASH_TOKEN: process.env.QSTASH_TOKEN,
    QSTASH_CURRENT_SIGNING_KEY: process.env.QSTASH_CURRENT_SIGNING_KEY,
    QSTASH_NEXT_SIGNING_KEY: process.env.QSTASH_NEXT_SIGNING_KEY,
    ROOT_DOMAIN: process.env.ROOT_DOMAIN,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    SLACK_ADMIN_MEMBER_ID: process.env.SLACK_ADMIN_MEMBER_ID,
    SLACK_BOT_TOKEN: process.env.SLACK_BOT_TOKEN,
    SLACK_SIGNING_SECRET: process.env.SLACK_SIGNING_SECRET,
    STRIPE_API_KEY: process.env.STRIPE_API_KEY,
    STRIPE_PRO_MONTHLY_PLAN_ID: process.env.STRIPE_PRO_MONTHLY_PLAN_ID,
    STRIPE_SOLO_MONTHLY_PLAN_ID: process.env.STRIPE_SOLO_MONTHLY_PLAN_ID,
    STRIPE_BUSINESS_MONTHLY_PLAN_ID:
      process.env.STRIPE_BUSINESS_MONTHLY_PLAN_ID,
    STRIPE_PRO_YEARLY_PLAN_ID: process.env.STRIPE_PRO_YEARLY_PLAN_ID,
    STRIPE_SOLO_YEARLY_PLAN_ID: process.env.STRIPE_SOLO_YEARLY_PLAN_ID,
    STRIPE_BUSINESS_YEARLY_PLAN_ID: process.env.STRIPE_BUSINESS_YEARLY_PLAN_ID,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    TIPTAP_AI_SECRET: process.env.TIPTAP_AI_SECRET,
    TIPTAP_COLLAB_SECRET: process.env.TIPTAP_COLLAB_SECRET,
    UNIFIED_API_KEY: process.env.UNIFIED_API_KEY,
    UNIFIED_ENVIRONMENT: process.env.UNIFIED_ENVIRONMENT,
    UNIFIED_WORKSPACE_ID: process.env.UNIFIED_WORKSPACE_ID,
  },
});
