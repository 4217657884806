"use client";

import { ReactNode, useState } from "react";
import {
  QueryClientProvider as ClientProvider,
  QueryClient,
} from "@tanstack/react-query";

export function QueryClientProvider({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());

  return <ClientProvider client={queryClient}>{children}</ClientProvider>;
}
