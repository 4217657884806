import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { env } from "@repo/env";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(
  input: string | number | Date,
  locale?: Intl.LocalesArgument | undefined,
  options?: Intl.DateTimeFormatOptions | undefined
): string {
  let date: Date;
  if (typeof input === "string" || typeof input === "number") {
    date = new Date(input);
  } else {
    date = input;
  }
  return date.toLocaleDateString(locale, options);
}

export const formatNumber = (value: number) =>
  new Intl.NumberFormat(undefined, {
    style: "currency",
  }).format(value);

export function formatDateTime(
  input: string | number | Date,
  locale?: Intl.LocalesArgument | undefined,
  options?: Intl.DateTimeFormatOptions | undefined
): string {
  let date: Date;
  if (typeof input === "string" || typeof input === "number") {
    date = new Date(input);
  } else {
    date = input;
  }
  return date.toLocaleString(locale, options);
}

export const runAsyncFnWithoutBlocking = (
  fn: (...args: any) => Promise<any>
) => {
  fn();
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

// Fake data
export function getStockPrice(name: string) {
  let total = 0;
  for (let i = 0; i < name.length; i++) {
    total = (total + name.charCodeAt(i) * 9999121) % 9999;
  }
  return total / 100;
}

export function absoluteUrl(path: string) {
  return `${env.NEXT_PUBLIC_URL}${path}`;
}

export function absoluteAppUrl(path: string) {
  return `${env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function getSubdomain(domain: string) {
  return domain.replace(`.${env.ROOT_DOMAIN}`, "");
}

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }

  return res.json();
}

export function getIsSecureUrl() {
  return env.NEXT_PUBLIC_APP_URL.startsWith("https://");
}

export function getCookieHostname() {
  const hostname = new URL(env.NEXT_PUBLIC_APP_URL).hostname;
  const [subDomain] = hostname.split(".");

  const cookieDomain = hostname.replace(`${subDomain}.`, "");
  return cookieDomain;
}

export function getHostFromNewSubdomain(
  currentHost: string,
  newSubdomain: string
) {
  const [currentSubdomain] = currentHost.split(".");

  const updatedHost = currentHost.replace(
    `${currentSubdomain}.`,
    `${newSubdomain}.`
  );
  return updatedHost;
}

export function getUrlFromNewSubdomain(
  currentHost: string,
  newSubdomain: string
) {
  const updatedHost = getHostFromNewSubdomain(currentHost, newSubdomain);
  const workspaceUrl = `https://${updatedHost}`;
  return workspaceUrl;
}

export function getRequestSubdomainSync(host: string) {
  const [subdomain] = host.split(".") || [];
  return subdomain;
}

export function getWorkspaceSlugFromHost(currentHost: string) {
  const [currentSubdomain] = currentHost.split(".");
  return currentSubdomain;
}

export function formatFileSize(size: number) {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

export const isProd = process.env.NODE_ENV === "production";
export const isDev = !isProd;
export const isServer = typeof window === "undefined";
export const isBrowser = !isServer;
