"use client";

import { useEffect } from "react";

interface Props {
  domain: string;
}

export function Cookies({ domain }: Props) {
  useEffect(() => {
    if (domain) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // Set a cookie with the timezone
      document.cookie = `timezone=${timezone}; domain=${domain}; path=/; max-age=31536000; secure`;
    }
  }, [domain]);

  return null;
}
